import React from 'react';

export const StarIcon = (props) => (
  <svg viewBox="0 0 30 29" fill="none" {...props}>
    <path
      d="M10.275 22.738 15 19.887l4.725 2.887-1.238-5.4 4.163-3.6-5.475-.488L15 8.188l-2.175 5.063-5.475.488 4.162 3.637-1.237 5.363ZM5.737 29l2.438-10.538L0 11.375l10.8-.938L15 .5l4.2 9.938 10.8.937-8.175 7.087L24.263 29 15 23.413 5.738 29Z"
      fill="#333"
    />
  </svg>
);
