import React from "react";

/*
   This function converts hex values to rgb
   It is taken from https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb 
*/
export function hexToRgbWriter(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  var res = [
    "RGB (" +
      parseInt(result[1], 16) +
      ", " +
      parseInt(result[2], 16) +
      ", " +
      parseInt(result[3], 16) +
      ")",
  ];
  return res;
}

export function hexToHSLWriter(H) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length == 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length == 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  var result =
    "HSL" +
    " (" +
    Math.round(h) +
    ", " +
    Math.round(s) +
    "%, " +
    Math.round(l) +
    "%)";
  return result;
}
